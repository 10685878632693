import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { RadioGroup } from '@headlessui/react';

import clsx from 'clsx';
import { useCopyToClipboard } from 'usehooks-ts';

import cardsAPI from '@/api/cards';
import profilesAPI from '@/api/profiles';
import { ReactComponent as BlankProfileAvatar } from '@/assets/icons/default_avatar.svg';
import { Alert } from '@/components/Alert';
import Copy from '@/components/Icons/Copy';
import LoadingAnimation from '@/components/LoadingAnimation';
import ModalFullWidth from '@/components/Modals/ModalFullWidth';
import Pagination from '@/components/Pagination';
import Search from '@/components/Search';
import Sort, { ISortOption } from '@/components/Sort';
import MESSAGES from '@/constants/messages-en';
import classNames from '@/helpers/classNames';
import { getVariantFullName } from '@/helpers/products';
import { getFullName, hashTruncate } from '@/helpers/strings';
import useAuth from '@/hooks/useAuth';
import { ProductName } from '@/types/IProduct';
import { IProfileBase } from '@/types/IProfile';

type ReassignCardModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  product: ProductName;
  cardHash: string;
  setActionResult: React.Dispatch<React.SetStateAction<Alert | undefined>>;
  onSuccessCallback?: () => void;
};

const sortOptions: ISortOption[] = [
  { sort: 'date', order: 'asc', label: 'Newest first' },
  { sort: 'date', order: 'desc', label: 'Oldest first' },
  { sort: 'first_name', order: 'asc', label: 'Name (A-Z)' },
  { sort: 'first_name', order: 'desc', label: 'Name (Z-A)' },
];

export function ReassignCardModal({
  isOpen,
  setIsOpen,
  product,
  cardHash,
  setActionResult,
  onSuccessCallback,
}: ReassignCardModalProps) {
  const { orgID } = useAuth();

  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('asc');
  const [sort, setSort] = useState('date');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [selectedProfile, setSelectedProfile] = useState<string | undefined>(
    undefined,
  );
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  const { data: profiles } = useQuery(
    ['listProfiles', page, pageSize, sort, order, search],
    () => listProfiles({ page, pageSize, sort, order, search }),
    {
      enabled: orgID !== undefined,
    },
  );
  const { mutateAsync: reassignCard, isLoading: isReassigningCard } =
    useMutation({
      mutationKey: ['updateCard', cardHash, selectedProfile],
      mutationFn: updateCard,
      onSuccess: () => {
        setActionResult({
          outcome: 'success',
          message:
            'You have successfully reassigned your product to a different profile.',
        });
        setIsOpen(false);
        setSelectedProfile(undefined);
        onSuccessCallback?.();
      },
    });

  async function listProfiles({
    page,
    pageSize,
    sort,
    order,
    search,
  }: {
    page: number;
    pageSize: number;
    sort: string;
    order: string;
    search: string;
  }) {
    const res = await profilesAPI.listProfiles({
      orgID,
      sort,
      order,
      page,
      pageSize,
      search,
    });

    return res.data;
  }

  async function updateCard() {
    if (orgID === undefined || selectedProfile === undefined) {
      return;
    }

    await cardsAPI.updateCard(orgID, cardHash, selectedProfile);
  }

  return (
    <ModalFullWidth
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dialogTitle={`Reassign ${product} to a different profile`}
      successButtonText="Assign to product"
      onCancel={() => setSelectedProfile(undefined)}
      onClose={() => {
        setIsOpen(false);
        setSelectedProfile(undefined);
      }}
      onSuccess={reassignCard}
      isLoading={isReassigningCard}
      isDisabled={selectedProfile === undefined}
    >
      <div className="space-y-4 pt-4">
        <div className="flex justify-end space-x-2">
          <Search
            id={`search-reassign-card-modal-${search}-${page}-${pageSize}`}
            search={search}
            setSearch={setSearch}
            fetchQuery={searchQuery =>
              listProfiles({ page, pageSize, search: searchQuery, sort, order })
            }
          />
          <Sort
            id={`sort-reassign-card-modal-${sort}-${order}-${search}`}
            options={sortOptions}
            sort={sort}
            setSort={setSort}
            order={order}
            setOrder={setOrder}
            fetchQuery={(sortQuery, orderQuery) =>
              listProfiles({
                page,
                pageSize,
                search,
                sort: sortQuery,
                order: orderQuery,
              })
            }
          />
        </div>
        {profiles ? (
          profiles.data.length > 0 ? (
            <>
              <div className="hidden md:block max-h-[450px] rounded-lg border border-solid overflow-y-scroll overflow-hidden hide-scrollbar">
                <RadioGroup
                  // to avoid value changing from undefined to defined on initial selection
                  value={selectedProfile || ''}
                >
                  <table
                    className={clsx('w-full table-fixed max-w-full', {
                      'opacity-40': isPaginationLoading,
                    })}
                  >
                    <thead
                      className="bg-gray-100 border-b border-gray-200 sticky top-0"
                      style={{ zIndex: 2 }}
                    >
                      <tr className="uppercase text-gray-900">
                        <th scope="col" className="py-3 px-4 w-[5%]">
                          &nbsp;
                        </th>
                        <th
                          scope="col"
                          className="font-medium text-sm text-left p-3"
                        >
                          Name &amp; profile id
                        </th>
                        <th
                          scope="col"
                          className="font-medium text-sm text-left p-3"
                        >
                          Contact information
                        </th>
                        <th
                          scope="col"
                          className="font-medium text-sm py-3 text-left px-4"
                        >
                          Product/s
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {profiles.data.map((profile, index) => (
                        <ProfileItem
                          key={index}
                          profile={profile}
                          isSelected={selectedProfile === profile.profile_hash}
                          setSelectedProfile={setSelectedProfile}
                        />
                      ))}
                    </tbody>
                  </table>
                </RadioGroup>
              </div>
              <div className="md:hidden">
                <div className="bg-gray-100 border border-gray-300 rounded-md p-3 flex font-medium uppercase">
                  Name &amp; profile id, contact information &amp; product/s
                </div>
                <div className="max-h-[calc(100vh-50vh)] overflow-y-scroll overflow-hidden hide-scrollbar">
                  {profiles.data.map((profile, index) => (
                    <ProfileItemMobile
                      key={index}
                      profile={profile}
                      isSelected={selectedProfile === profile.profile_hash}
                      setSelectedProfile={setSelectedProfile}
                    />
                  ))}
                </div>
              </div>
              {isPaginationLoading && (
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                  <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
                </div>
              )}
            </>
          ) : (
            <div className="py-32">
              <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                {MESSAGES.profile.list.empty.heading}
              </h3>
              <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                {MESSAGES.profile.list.empty.description}
              </p>
            </div>
          )
        ) : (
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
          </div>
        )}
        <Pagination
          id={`pagination-reassign-card-${page}-${pageSize}-${search}`}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          setIsLoading={setIsPaginationLoading}
          fetchQuery={(pageQuery, pageSizeQuery) =>
            listProfiles({
              page: pageQuery,
              pageSize: pageSizeQuery,
              sort,
              order,
              search,
            })
          }
        />
      </div>
    </ModalFullWidth>
  );
}

type ProfileItemProps = {
  profile: IProfileBase;
  isSelected: boolean;
  setSelectedProfile: React.Dispatch<React.SetStateAction<string | undefined>>;
};

function ProfileItem({
  profile,
  isSelected,
  setSelectedProfile,
}: ProfileItemProps) {
  const {
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    email,
    job_title: jobTitle,
    profile_hash: profileHash,
    photo,
    products,
  } = profile;

  const [, copy] = useCopyToClipboard();

  const [isCopied, setIsCopied] = useState(false);

  async function copyHashToClipboard(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    e.stopPropagation();
    await copy(profileHash);

    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  }

  const photoUrl = photo?.thumb_url;

  const fullName = getFullName({
    firstName,
    lastName,
    middleName,
  });

  return (
    <tr
      className="hover:bg-gray-50 relative cursor-pointer"
      onClick={() =>
        isSelected
          ? setSelectedProfile(undefined)
          : setSelectedProfile(profileHash)
      }
    >
      <td className="py-3 px-4">
        <RadioGroup.Option value={profileHash}>
          {({ active, checked }) => (
            <span
              className={classNames(
                checked
                  ? 'bg-brand-500 border-transparent'
                  : 'bg-white border-gray-300',
                active ? 'ring-2 ring-offset-2 ring-brand-500' : '',
                'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
              )}
              aria-hidden="true"
            >
              <span className="rounded-full bg-white w-1.5 h-1.5" />
            </span>
          )}
        </RadioGroup.Option>
      </td>
      <td className="whitespace-nowrap p-3">
        <div className="flex items-center flex-shrink-0 space-x-3 overflow-hidden">
          <div className="flex-shrink-0">
            {photoUrl ? (
              <img
                className="w-10 h-10 rounded-full"
                src={photoUrl}
                alt="Profile photo"
              />
            ) : (
              <BlankProfileAvatar />
            )}
          </div>
          <div className="flex flex-col truncate">
            {fullName && (
              <span className="text-gray-900 text-sm font-medium truncate">
                {fullName}
              </span>
            )}
            <span className="font-light text-sm text-gray-500 flex items-center">
              ID:&nbsp;
              <button
                type="button"
                className="text-brand-600 font-medium flex items-center gap-0.5"
                onClick={copyHashToClipboard}
                disabled={isCopied}
              >
                {isCopied ? 'Copied' : hashTruncate(profileHash)}
                <Copy className="w-4 h-4" />
              </button>
            </span>
          </div>
        </div>
      </td>
      <td className="p-3">
        <span className="flex flex-col">
          {jobTitle && (
            <span className="text-sm text-gray-900 font-medium">
              {jobTitle}
            </span>
          )}
          {email && (
            <span className="text-sm text-gray-500 truncate">{email}</span>
          )}
        </span>
      </td>
      <td className="py-3 px-4 text-sm">
        {products.map(product => getVariantFullName(product.type)).join(', ')}
      </td>
    </tr>
  );
}

function ProfileItemMobile({
  profile,
  isSelected,
  setSelectedProfile,
}: ProfileItemProps) {
  const {
    profile_hash: profileHash,
    job_title: jobTitle,
    email,
    first_name: firstName,
    last_name: lastName,
    middle_name: middleName,
    photo, // this type is wrong, it can be null
  } = profile;

  const [, copy] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);

  async function copyHashToClipboard(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    e.stopPropagation();
    await copy(profileHash);

    setIsCopied(true);
    setTimeout(() => setIsCopied(false));
  }

  const fullName = getFullName({
    firstName,
    middleName,
    lastName,
  });

  const photoUrl = photo?.thumb_url;

  return (
    <div
      className="relative border border-gray-300 bg-white rounded-md xl:hidden my-4 flex flex-row items-center p-6 shadow-sm gap-4"
      onClick={() =>
        isSelected
          ? setSelectedProfile(undefined)
          : setSelectedProfile(profileHash)
      }
    >
      <span className="self-start">
        <input
          type="radio"
          name="reassign-profile"
          value={profileHash}
          checked={isSelected}
          className="cursor-pointer border border-gray-300 text-brand-500 focus:outline-none focus:ring-0"
          onChange={e =>
            isSelected
              ? setSelectedProfile(undefined)
              : setSelectedProfile(e.currentTarget.value)
          }
        />
      </span>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-shrink-0 space-x-4">
          {photoUrl ? (
            <img
              src={photoUrl}
              className="w-10 h-10 rounded-full"
              alt="Profile photo"
            />
          ) : (
            <BlankProfileAvatar className="rounded-full" />
          )}
          <div className="flex flex-col">
            {fullName && (
              <span className="font-medium text-gray-900 text-sm">
                {fullName}
              </span>
            )}
            <span className="font-light text-gray-500 flex">
              ID:&nbsp;
              <button
                type="button"
                className="appearance-none text-brand-600 text-sm font-medium items-center flex"
                onClick={copyHashToClipboard}
                disabled={isCopied}
              >
                {isCopied ? 'Copied' : hashTruncate(profileHash)}
                <Copy className="w-4 h-4" />
              </button>
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          {jobTitle && (
            <span className="font-medium text-gray-900 text-sm">
              {jobTitle}
            </span>
          )}
          {email && <span className="text-gray-500 text-sm">{email}</span>}
        </div>
        <div className="flex flex-col">
          <span className="text-gray-900 text-sm font-medium">Products</span>
          <span className="text-sm text-gray-500">todo</span>
        </div>
      </div>
    </div>
  );
}
