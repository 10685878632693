import { useCallback, useEffect, useState } from 'react';

import { Tab } from '@headlessui/react';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { EyeIcon, InformationCircleIcon } from '@heroicons/react/24/outline';

import { useUpdateEffect } from 'usehooks-ts';

import accountsAPI from '@/api/accounts';
import filesAPI from '@/api/files';
import organisationsAPI from '@/api/organisations';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import ColorPicker from '@/components/ColorPicker';
import FileUploadButtonComponent from '@/components/FileUploadButton';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import Input from '@/components/Input';
import Layout from '@/components/Layout';
import Modal from '@/components/Modals/Modal';
import { ProfilePreviewModal } from '@/components/ProfilePreview';
import ManagePlanTab from '@/components/SettingsPage/ManagePlanTab';
import TabList from '@/components/TabList';
import ToggleField from '@/components/ToggleField';
import ToggleFieldWithInfo from '@/components/ToggleFieldWithInfo';
import UploadPhoto from '@/components/UploadPhoto';
import { COVER_IMAGE } from '@/constants/files';
import MESSAGES from '@/constants/messages-en';
import OrganisationSettings from '@/containers/OrganisationSettings';
import useAppState, { SettingsTab } from '@/hooks/useAppState';
import useAuth from '@/hooks/useAuth';
import { useResponsiveIntercomButton } from '@/hooks/useResponsiveIntercomButton';
import IFile from '@/types/IFile';
import { IOrganisationUser } from '@/types/IOrganisation';
import SubscriptionStatus from '@/types/SubscriptionStatus';

interface IAccountSettingsPage {
  location: {
    state: {
      [key: string]: string;
    };
  };
}

export default function AccountSettingsPage(props: IAccountSettingsPage) {
  const { user, orgID, getCurrentUser, userRole, platformSubscriptionStatus } =
    useAuth();
  const { settingsTab, selectSettingsTab } = useAppState();
  const { location } = props;

  const disableTabs =
    platformSubscriptionStatus === SubscriptionStatus.INACTIVE;
  const [isPlanRequiredModalOpen, setIsPlanRequiredModalOpen] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState('');
  const [tabs, setTabs] = useState([
    {
      name: 'General',
      value: 'general',
      current: true,
      disabled: disableTabs,
    },
    {
      name: 'Organisation',
      value: 'organisation',
      current: false,
      disabled: disableTabs,
    },
    {
      name: 'Profile',
      value: 'profile',
      current: false,
      disabled: disableTabs,
    },
    {
      name: 'Contact Exchange',
      value: 'contact-exchange',
      current: false,
      disabled: disableTabs,
    },
    {
      name: 'Profile & Editing',
      value: 'profile-editing',
      current: false,
      disabled: disableTabs,
    },
    {
      name: 'Manage Plan',
      value: 'manage-plan',
      current: false,
    },
  ]);

  const defaultTab = disableTabs
    ? SettingsTab.MANAGE_PLAN
    : tabs[settingsTab]
    ? settingsTab
    : SettingsTab.GENERAL;
  const [selectedIndex, setSelectedIndex] = useState<number>(defaultTab);
  const [tab, setTab] = useState(tabs[defaultTab].value);

  useResponsiveIntercomButton(
    ['profile-editing', 'contact-exchange'].includes(tab),
  );

  useEffect(() => {
    let tabIndex = settingsTab;
    const tab = tabs[settingsTab];
    if (!tab || tab.disabled) {
      tabIndex = defaultTab;
    }

    setTab(tabs[tabIndex].value);
    setSelectedIndex(tabIndex);
  }, [settingsTab, tabs]);

  const [firstName, setFirstName] = useState<string>(user?.first_name || '');
  const [lastName, setLastName] = useState<string>(user?.last_name || '');
  const [email, setEmail] = useState<string>(user?.email || '');
  const [mfaEnabled, setMfaEnabled] = useState(
    user?.authentication_type === 'mfa_email',
  );
  const [organisationName, setOrganisationName] = useState<string>('');
  const [logoFile, setLogoFile] = useState<IFile>();
  const [backgroundColour, setBackgroundColour] = useState<string>('#0A0A0A');
  const [textColour, setTextColour] = useState<string>('#FFFFFF');
  const [buttonBackgroundColour, setButtonBackgroundColour] =
    useState<string>('#873CFF');
  const [buttonTextColour, setButtonTextColour] = useState<string>('#FFFFFF');
  const [fullNameToggle, setFullNameToggle] = useState<boolean>(true);
  const [emailToggle, setEmailToggle] = useState<boolean>(true);
  const [mobileNumberToggle, setMobileNumberToggle] = useState<boolean>(true);
  const [jobTitleToggle, setJobTitleToggle] = useState<boolean>(false);
  const [companyNameToggle, setCompanyNameToggle] = useState<boolean>(false);
  const [consentStatementToggle, setConsentStatementToggle] =
    useState<boolean>(false);
  const [consentStatement, setConsentStatement] = useState<IFile>();
  const [consentSizeError, setConsentSizeError] = useState<boolean>(false);
  const [consentFileError, setConsentFileError] = useState<boolean>(false);
  const [consentLoading, setConsentLoading] = useState<boolean>(false);
  const [organisationError, setOrganisationError] = useState<boolean>(false);
  const [resetPasswordIsOpen, setResetPasswordIsOpen] =
    useState<boolean>(false);
  const [inviteUserEmail, setInviteUserEmail] = useState<string>('');
  const [inviteUserRole, setInviteUserRole] = useState<string>('org_admin');
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [orgUsers, setOrgUsers] = useState<IOrganisationUser[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordLoading, setPasswordLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>('');
  const [passwordSuccess, setPasswordSuccess] = useState<string>('');
  const [isCardholderEditingEnabled, setIsCardholderEditingEnabled] =
    useState<boolean>(true);
  const [isCardholderEditingEmailOpen, setIsCardholderEditingEmailOpen] =
    useState<boolean>(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const handleGeneralSettingsSave = () => {
    setSuccess('');
    setLoading(true);

    accountsAPI
      .updateCurrentUser({
        first_name: firstName,
        last_name: lastName,
      })
      .then(() => setSuccess(MESSAGES.account.settings.success))
      .then(() => getCurrentUser())
      .finally(() => setLoading(false));
  };

  const handleOrganisationSettingsSave = () => {
    setSuccess('');
    setOrganisationError(false);
    setLoading(true);

    if (orgID) {
      organisationsAPI
        .updateOrganisationSettings(orgID, {
          organisation_setting: {
            bg_color: backgroundColour,
            text_color: textColour,
            button_bg_color: buttonBackgroundColour,
            button_text_color: buttonTextColour,
            contact_exchange: {
              company_name: companyNameToggle,
              email: emailToggle,
              job_title: jobTitleToggle,
              mobile_number: mobileNumberToggle,
              name: fullNameToggle,
              company_consent: consentStatementToggle,
            },
            company_logo_file_id: logoFile ? logoFile.id : null,
            company_consent_file: consentStatement,
            company_consent_file_id: consentStatement
              ? consentStatement.id
              : null,
            is_cardholder_editing_enabled: isCardholderEditingEnabled,
          },
        })
        .then(() => setSuccess(MESSAGES.organisation.settings))
        .catch(() => setOrganisationError(true))
        .finally(() => setLoading(false));
    }
  };

  const handleProfileEditingAccessSave = (emailProfileEditing?: boolean) => {
    setSuccess('');
    setOrganisationError(false);
    setLoading(true);

    if (orgID) {
      organisationsAPI
        .updateOrganisationSettings(orgID, {
          email_profile_editing: emailProfileEditing,
          organisation_setting: {
            is_cardholder_editing_enabled: isCardholderEditingEnabled,
          },
        })
        .then(() => setSuccess(MESSAGES.organisation.settings))
        .catch(() => setOrganisationError(true))
        .finally(() => setLoading(false));
    }
  };

  const handleEmailModal = () => {
    if (isCardholderEditingEnabled) {
      setIsCardholderEditingEmailOpen(true);
    } else {
      handleProfileEditingAccessSave(false);
    }
  };

  const handleResetPassword = () => {
    setPasswordSuccess('');
    setPasswordError(false);
    setPasswordLoading(true);
    accountsAPI
      .forgotPassword({ forgot: { email } })
      .then(() => setPasswordSuccess(MESSAGES.account.reset.success))
      .catch(() => setPasswordError(true))
      .then(() => setPasswordLoading(false))
      .finally(() => setResetPasswordIsOpen(false));
  };

  const showOrganisationSettings = useCallback(() => {
    if (orgID) {
      setLoading(true);
      organisationsAPI
        .showOrganisationSettings(orgID)
        .then(res => {
          const data = res?.data?.data;
          if (data.bg_color) setBackgroundColour(data.bg_color);
          if (data.text_color) setTextColour(data.text_color);
          if (data.button_bg_color)
            setButtonBackgroundColour(data.button_bg_color);
          if (data.button_text_color)
            setButtonTextColour(data.button_text_color);
          if (data?.contact_exchange?.name)
            setFullNameToggle(data.contact_exchange.name);
          if (data?.contact_exchange?.company_name)
            setCompanyNameToggle(data.contact_exchange.company_name);
          if (data?.contact_exchange?.email)
            setEmailToggle(data.contact_exchange.email);
          if (data?.contact_exchange?.job_title)
            setJobTitleToggle(data.contact_exchange.job_title);
          if (data?.contact_exchange?.mobile_number)
            setMobileNumberToggle(data.contact_exchange.mobile_number);
          if (data?.contact_exchange?.company_consent)
            setConsentStatementToggle(data.contact_exchange.company_consent);
          if (data?.company_consent_file)
            setConsentStatement(data.company_consent_file);
          if (data.company_logo) setLogoFile(data.company_logo);
          setIsCardholderEditingEnabled(data.is_cardholder_editing_enabled);
        })
        .finally(() => {
          setLoading(false);
          if (location && location.state) {
            const { fromPage } = location.state;
            if (fromPage === 'preview') {
              if (location.state.bg_color)
                setBackgroundColour(location.state.bg_color);
              if (location.state.text_color)
                setTextColour(location.state.text_color);
              if (location.state.button_bg_color)
                setButtonBackgroundColour(location.state.button_bg_color);
              if (location.state.button_text_color)
                setButtonTextColour(location.state.button_text_color);
              if (location.state.company_logo_file_id) {
                filesAPI
                  .showFile(orgID, Number(location.state.company_logo_file_id))
                  .then(res => setLogoFile(res.data.data))
                  .finally(() => {});
              }
            }
            if (location.state.company_consent_file_id) {
              filesAPI
                .showFile(orgID, Number(location.state.company_consent_file_id))
                .then(res => setConsentStatement(res.data.data))
                .finally(() => {});
            }
          }
        });
    }
  }, [orgID, location]);

  const renderInvalidPlanInfo = () => {
    if (selectedIndex !== SettingsTab.MANAGE_PLAN) return false;
    if (platformSubscriptionStatus !== SubscriptionStatus.INACTIVE)
      return false;

    return (
      <div className="rounded-md bg-brand-100 max-w-2xl text-white p-3 md:mt-0 right-0">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-brand-900"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-brand-900">
              tapt Management Dashboard plan invalid
            </h3>
            <p className="mt-2 text-sm leading-5 text-brand-600">
              You currently do not have an active plan to access the tapt
              Management Dashboard. If you would like access to management
              features, activate your plan below.{' '}
              <button onClick={() => setIsPlanRequiredModalOpen(true)}>
                <span className="underline">Learn more</span>
              </button>
            </p>
            <a
              href={checkoutUrl}
              target="_blank"
              rel="noreferrer"
              className="mt-2 text-sm leading-5 font-medium text-brand-500 flex w-full"
            >
              Join now
              <ArrowRightIcon className="ml-2 w-5 h-5" />
            </a>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    showOrganisationSettings();
  }, [showOrganisationSettings]);

  useUpdateEffect(() => {
    setSuccess('');
    setLoading(true);
    accountsAPI
      .updateCurrentUser({
        authentication_type: mfaEnabled ? 'mfa_email' : 'password',
      })
      .then(() => setSuccess(MESSAGES.account.settings.success))
      .then(() => getCurrentUser())
      .finally(() => setLoading(false));
  }, [mfaEnabled]);

  return (
    <Layout pageName="Settings" rightTitle={renderInvalidPlanInfo()}>
      {userRole === 'org_admin' && (
        <Tab.Group
          defaultIndex={settingsTab}
          selectedIndex={selectedIndex}
          onChange={(index: number) => {
            setSelectedIndex(index);
            setTab(tabs[index].value);
            selectSettingsTab(index);
            setSuccess('');
            setOrganisationError(false);
            setPasswordError(false);
            setLoading(false);
          }}
        >
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full md:border-b border-gray-200">
            <div className="pt-4 md:pt-0 w-full md:w-auto">
              <TabList tabs={tabs} value={tab} />
            </div>
          </div>
          <Tab.Panels>
            <div className="pt-4">
              {success !== '' && <SuccessAlert message={success} />}
            </div>
            <Tab.Panel className="outline-none">
              <Modal
                isOpen={resetPasswordIsOpen}
                setIsOpen={setResetPasswordIsOpen}
                dialogTitle="Reset password"
                dialogDescription="This will send an email with instructions to reset your password."
                successButtonText="Reset password"
                onSuccess={handleResetPassword}
                isLoading={passwordLoading}
              />
              <div className="mt-10">
                <InfoPanelContainer
                  title="Account settings"
                  footerContent={() => (
                    <Button
                      buttonText="Save"
                      loading={loading}
                      onClick={handleGeneralSettingsSave}
                    />
                  )}
                >
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <Input
                        label="First name"
                        type="text"
                        value={firstName}
                        onChange={setFirstName}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <Input
                        label="Last Name"
                        type="text"
                        value={lastName}
                        onChange={setLastName}
                      />
                    </div>
                    <div className="mt-3 col-span-8 sm:col-span-4">
                      <Input
                        label="Email Address"
                        type="text"
                        value={email}
                        onChange={setEmail}
                        disabled
                      />
                    </div>
                  </div>
                </InfoPanelContainer>
                <InfoPanelDivider />
                <InfoPanelContainer title="Account security">
                  {passwordSuccess !== '' && (
                    <SuccessAlert message={passwordSuccess} />
                  )}
                  {passwordError && (
                    <ErrorAlert message="Something went wrong. Please try again later" />
                  )}
                  <div className="grid grid-cols-1 gap-6">
                    <ToggleFieldWithInfo
                      label="Multi-factor authentication"
                      enabled={mfaEnabled}
                      setter={setMfaEnabled}
                      modalTitle="What is multi-factor authentication?"
                      modalDescription="mfa_content"
                    />
                  </div>
                  <div className="mt-5 flex flex-col lg:flex-row items-start lg:items-center justify-between">
                    <div className="min-w-0 text-sm leading-5 font-medium text-gray-900 mt-0.5">
                      Reset Password
                    </div>
                    <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-start lg:items-center mt-5 sm:mt-0">
                      <Button
                        kind={BUTTON_KIND.WHITE}
                        buttonText="Reset"
                        onClick={() => setResetPasswordIsOpen(true)}
                      />
                    </div>
                  </div>
                </InfoPanelContainer>
              </div>
            </Tab.Panel>
            <Tab.Panel className="outline-none">
              {orgID && (
                <OrganisationSettings
                  orgID={orgID}
                  organisationName={organisationName}
                  setOrganisationName={setOrganisationName}
                  inviteUserEmail={inviteUserEmail}
                  setInviteUserEmail={setInviteUserEmail}
                  inviteUserRole={inviteUserRole}
                  setInviteUserRole={setInviteUserRole}
                  orgUsers={orgUsers}
                  setOrgUsers={setOrgUsers}
                />
              )}
            </Tab.Panel>
            <Tab.Panel className="outline-none">
              <div className="mt-10">
                <InfoPanelContainer
                  information="Customise the look of the profile pages of your cardholders. Changes will apply to all profile pages belonging to your organisation."
                  title="Profile page appearance"
                  footerContent={() => (
                    <>
                      <Button
                        buttonText="Preview"
                        kind={BUTTON_KIND.WHITE}
                        icon={<EyeIcon strokeWidth={2} />}
                        onClick={() => setIsPreviewOpen(true)}
                      />
                      <ProfilePreviewModal
                        isOpen={isPreviewOpen}
                        setIsOpen={setIsPreviewOpen}
                        settings={{
                          bgColor: backgroundColour,
                          textColor: textColour,
                          buttonBgColor: buttonBackgroundColour,
                          buttonTextColor: buttonTextColour,
                          companyLogo: logoFile,
                        }}
                      />
                      <Button
                        buttonText="Save"
                        loading={loading}
                        onClick={handleOrganisationSettingsSave}
                      />
                    </>
                  )}
                >
                  {organisationError && (
                    <ErrorAlert message="Something went wrong. Please try again later" />
                  )}
                  <h4 className="text-xl leading-7 font-medium text-gray-900">
                    Cover image
                  </h4>
                  <p className="text-sm leading-5 text-gray-500">
                    Choose an image to display at the top of cardholder profile
                    pages.
                  </p>
                  <div className="flex items-center mt-4 mb-0">
                    <UploadPhoto
                      title="Cover image"
                      photo={logoFile}
                      setPhoto={setLogoFile}
                      size="large"
                      aspectRatio={16 / 11}
                      fileFormatMessage="Recommended dimensions 1024px x 704px"
                      maxHeight={COVER_IMAGE.MAX_HEIGHT}
                      maxWidth={COVER_IMAGE.MAX_WIDTH}
                    />
                  </div>
                  <h4 className="text-xl leading-7 font-medium text-gray-900">
                    Colours
                  </h4>
                  <p className="text-sm leading-5 text-gray-500">
                    Create a custom theme for cardholder profile pages. Maintain
                    good readability by ensuring there is sufficient contrast
                    between text and background colours.
                  </p>
                  <div className="grid grid-cols-8 gap-4">
                    <div className="col-span-6">
                      <div className="mt-4 text-left">
                        <p className="block mb-2 text-sm leading-5 font-medium text-gray-900">
                          Page background color
                        </p>
                        <ColorPicker
                          color={backgroundColour}
                          setColor={setBackgroundColour}
                        />
                      </div>
                    </div>
                    <div className="col-span-6">
                      <div className="mt-4 text-left">
                        <p className="block mb-2 text-sm leading-5 font-medium text-gray-900">
                          Text color
                        </p>
                        <ColorPicker
                          color={textColour}
                          setColor={setTextColour}
                        />
                      </div>
                    </div>
                    <div className="col-span-6">
                      <div className="mt-4 text-left">
                        <p className="block mb-2 text-sm leading-5 font-medium text-gray-900">
                          Button background color
                        </p>
                        <ColorPicker
                          color={buttonBackgroundColour}
                          setColor={setButtonBackgroundColour}
                        />
                      </div>
                    </div>
                    <div className="col-span-6">
                      <div className="mt-4 text-left">
                        <p className="block mb-2 text-sm leading-5 font-medium text-gray-900">
                          Button text color
                        </p>
                        <ColorPicker
                          color={buttonTextColour}
                          setColor={setButtonTextColour}
                        />
                      </div>
                    </div>
                  </div>
                </InfoPanelContainer>
              </div>
            </Tab.Panel>
            <Tab.Panel className="outline-none">
              <div className="mt-10">
                <InfoPanelContainer
                  title="Contact exchange form settings"
                  information={MESSAGES.contact.exchange.description}
                >
                  {organisationError && (
                    <ErrorAlert message="Something went wrong. Please try again later" />
                  )}
                  <div className="grid grid-cols-1 gap-5">
                    <ToggleField
                      label="Full name"
                      enabled={fullNameToggle}
                      setter={setFullNameToggle}
                      disabled
                      message="Mandatory field"
                    />
                    <ToggleField
                      label="Email"
                      enabled={emailToggle}
                      setter={setEmailToggle}
                    />
                    <ToggleField
                      label="Mobile number"
                      enabled={mobileNumberToggle}
                      setter={setMobileNumberToggle}
                    />
                    <ToggleField
                      label="Job title/Position"
                      enabled={jobTitleToggle}
                      setter={setJobTitleToggle}
                    />
                    <ToggleField
                      label="Company name"
                      enabled={companyNameToggle}
                      setter={setCompanyNameToggle}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-5 mt-5">
                    {!consentStatement && (
                      <ToggleFieldWithInfo
                        className="text-gray-600 text-sm font-normal leading-[150%]"
                        label="Consent Statement"
                        enabled={consentStatementToggle}
                        setter={setConsentStatementToggle}
                        disabled
                        modalTitle="Consent Statement"
                        modalDescription="If your company has data policies they would like future contacts to consent to, add the policy document here. We will ask contacts to approve the policies before they send their details back to you!"
                      />
                    )}
                    {consentStatement && (
                      <ToggleFieldWithInfo
                        label="Consent Statement"
                        enabled={consentStatementToggle}
                        setter={setConsentStatementToggle}
                        modalTitle="Consent Statement"
                        modalDescription="If your company has data policies they would like future contacts to consent to, add the policy document here. We will ask contacts to approve the policies before they send their details back to you!"
                      />
                    )}
                  </div>
                  <div className="flex mt-0 pt-0">
                    {orgID && (
                      <FileUploadButtonComponent
                        id="consent-statement-upload"
                        filename={consentStatement?.file?.file_name}
                        url={consentStatement?.original_url}
                        onFileSelected={file => {
                          setConsentLoading(true);
                          filesAPI
                            .createAndUploadFile(
                              orgID,
                              new File([file], file.name),
                            )

                            .then(res => {
                              if (res?.data?.data.id) {
                                setConsentStatement(res.data.data);
                                setConsentStatementToggle(true);
                              }
                            })
                            .catch(() => setConsentFileError(true))
                            .finally(() => setConsentLoading(false));
                        }}
                        buttonTitle="Upload consent statement"
                        fileFormat=".pdf, .jpeg, .png"
                        fileFormatMessage="(.jpeg, .png or .pdf only)"
                        loading={consentLoading}
                        sizeError={consentSizeError}
                        fileError={consentFileError}
                      />
                    )}
                    {consentStatement?.file?.file_name && (
                      <button
                        type="button"
                        onClick={() => {
                          setConsentStatement(undefined);
                          setConsentStatementToggle(false);
                        }}
                        className="appearance-none ml-2 text-sm leading-5 text-brand-500"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </InfoPanelContainer>
              </div>
              <div className="mb-8">
                <InfoPanelFooter>
                  <div className="flex justify-end">
                    <Button
                      buttonText="Discard changes"
                      className="mr-3"
                      kind={BUTTON_KIND.WHITE}
                      onClick={window.location.reload}
                    />
                    <Button
                      buttonText="Save"
                      onClick={handleOrganisationSettingsSave}
                    />
                  </div>
                </InfoPanelFooter>
              </div>
            </Tab.Panel>
            <Tab.Panel className="outline-none">
              <div className="mt-10">
                <InfoPanelContainer
                  title="Employee editing access"
                  information="Grant employees access to edit their own profiles within the Tapt app"
                >
                  <ToggleField
                    label="Grant all employees profile editing access"
                    enabled={isCardholderEditingEnabled}
                    setter={setIsCardholderEditingEnabled}
                  />
                  <InfoPanelFooter>
                    <div className="flex justify-end">
                      <Button
                        buttonText="Discard changes"
                        className="mr-3"
                        kind={BUTTON_KIND.WHITE}
                        onClick={window.location.reload}
                      />
                      <Button buttonText="Save" onClick={handleEmailModal} />
                    </div>
                  </InfoPanelFooter>
                </InfoPanelContainer>
                <Modal
                  isOpen={isCardholderEditingEmailOpen}
                  setIsOpen={setIsCardholderEditingEmailOpen}
                  dialogTitle="Do you want to notify the cardholders?"
                  dialogDescription="By clicking send email, these people will receive an email notification that they can edit their profiles on the Tapt App."
                  onSuccess={() => {
                    setIsCardholderEditingEmailOpen(false);
                    handleProfileEditingAccessSave(true);
                  }}
                  onCancel={() => {
                    setIsCardholderEditingEmailOpen(false);
                    handleProfileEditingAccessSave(false);
                  }}
                  successButtonText="Send Email"
                  cancelButtonText="Don't send"
                  buttonKind={BUTTON_KIND.CIRCULAR}
                />
              </div>
            </Tab.Panel>
            <Tab.Panel className="outline-none">
              <div className="mt-10">
                <ManagePlanTab
                  organisationId={orgID}
                  isPlanRequiredModalOpen={isPlanRequiredModalOpen}
                  setIsPlanRequiredModalOpen={setIsPlanRequiredModalOpen}
                  checkoutUrl={checkoutUrl}
                  setCheckoutUrl={setCheckoutUrl}
                />
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      )}
    </Layout>
  );
}
