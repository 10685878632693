import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import Router from '@/components/Router';
import { StateProvider } from '@/hooks/useAppState';
import { AuthProvider } from '@/hooks/useAuth';

import { DialogProvider } from './hooks/useDialog';
import { ShopifyProvider } from './hooks/useShopify';

const queryClient = new QueryClient();

function App() {
  return (
    <BrowserRouter>
      <IntercomProvider
        appId={import.meta.env.VITE_INTERCOM_WORKSPACE_ID}
        autoBoot
      >
        <AuthProvider>
          <StateProvider>
            <QueryClientProvider client={queryClient}>
              <ShopifyProvider>
                <DialogProvider>
                  <Router />
                </DialogProvider>
              </ShopifyProvider>
            </QueryClientProvider>
          </StateProvider>
        </AuthProvider>
      </IntercomProvider>
    </BrowserRouter>
  );
}

export default App;
