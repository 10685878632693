import { useState } from 'react';
import { useQuery } from 'react-query';

import clsx from 'clsx';

import organisationsAPI from '@/api/organisations';
import profilesAPI from '@/api/profiles';
import { Alert, ErrorAlert, SuccessAlert } from '@/components/Alert';
import Filter, {
  DEFAULT_GROUP_OPTION,
  GroupFilterOption,
} from '@/components/Filter';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import InputCheckbox from '@/components/InputCheckbox';
import Layout from '@/components/Layout';
import LoadingAnimation from '@/components/LoadingAnimation';
import Pagination from '@/components/Pagination';
import {
  ProfileListRow,
  ProfileListRowMobile,
} from '@/components/ProfileListItem';
import ProfilesGroupsPseudoTabs from '@/components/ProfilesGroupsPseudoTabs';
import { ProfilesPageFooter } from '@/components/ProfilesListPage/Footer';
import Search from '@/components/Search';
import Sort, { ISortOption } from '@/components/Sort';
import MESSAGES from '@/constants/messages-en';
import useAppState from '@/hooks/useAppState';
import useAuth from '@/hooks/useAuth';
import { IProfileBase } from '@/types/IProfile';

const sortOptions: ISortOption[] = [
  {
    sort: 'date',
    order: 'desc',
    label: 'Newest first',
  },
  {
    sort: 'date',
    order: 'asc',
    label: 'Oldest first',
  },
  {
    sort: 'first_name',
    order: 'asc',
    label: 'Name (A-Z)',
  },
  {
    sort: 'first_name',
    order: 'desc',
    label: 'Name (Z-A)',
  },
];

function ProfileListGroupPage() {
  const { user, orgID } = useAuth();
  const { selectProfiles: selectProfilesGlobal } = useAppState();

  const [page, setPage] = useState(1);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');
  const [selectedGroup, setSelectedGroup] =
    useState<GroupFilterOption>(DEFAULT_GROUP_OPTION);
  const [selectedProfiles, setSelectedProfiles] = useState(
    new Set<IProfileBase>(),
  );
  const [actionResult, setActionResult] = useState<Alert | undefined>(
    undefined,
  );
  const [selectAll, setSelectAll] = useState(false);
  const [filterOptions, setFilterOptions] = useState<GroupFilterOption[]>([]);

  const editorsGroup = filterOptions
    .filter(group => group.id !== 0)
    .map(group => group.id);

  const {
    data: groups,
    isError: errorGroups,
    isFetching: isGroupsFetching,
  } = useQuery('listGroups', listGroups, {
    enabled: orgID !== undefined && user !== undefined,
  });

  const {
    data: profiles,
    refetch: refreshProfiles,
    isError: errorProfiles,
  } = useQuery(
    [
      'listProfilesInGroup',
      page,
      pageSize,
      sort,
      order,
      search,
      editorsGroup,
      selectedGroup.id,
    ],
    () => {
      return listProfilesInGroups();
    },
    {
      enabled: groups !== undefined,
    },
  );

  const error = errorGroups || errorProfiles;

  async function listGroups() {
    if (orgID === undefined || user === undefined) {
      return;
    }

    const { data: groups } = await organisationsAPI.listOrganisationGroups({
      orgID,
      page: 1,
      pageSize: 500,
    });

    const groupList: GroupFilterOption[] = [];

    groups.data.forEach(group => {
      if (group.editors.length > 0) {
        group.editors.forEach(editor => {
          if (editor.user.id === user.id) {
            groupList.push({ id: group.id, name: group.name });
          }

          if (groupList.length === 0 && editor.user.id === user.id) {
            setSelectedGroup({ name: group.name, id: group.id });
          }
        });
      }
    });

    setFilterOptions([DEFAULT_GROUP_OPTION, ...groupList]);

    return groups;
  }

  async function listProfilesInGroups(
    newPage: number = page,
    newPageSize: number = pageSize,
    newSort: string = sort,
    newOrder: string = order,
    newSearch: string = search,
    groupIDs: number[] = editorsGroup,
    groupID: number = selectedGroup.id,
  ) {
    if (editorsGroup.length === 0) {
      return {
        data: [],
        paging: {
          page_number: 1,
          page_offset: 0,
          page_size: 20,
          total_entries: 0,
          total_pages: 0,
        },
      };
    }

    if (selectedGroup.id === 0) {
      const { data: profiles } = await profilesAPI.listProfilesInGroups({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        search: newSearch,
        groupIDs,
      });

      return profiles;
    } else {
      const { data: profiles } = await profilesAPI.listProfilesInGroup({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        search: newSearch,
        groupID,
      });

      return profiles;
    }
  }

  function handleCheckItem(profile: IProfileBase) {
    let arr = Array.from(selectedProfiles);

    if (selectedProfiles.has(profile)) {
      arr = arr.filter(e => e !== profile);
    } else {
      arr = [...selectedProfiles, profile];
    }

    setSelectedProfiles(new Set(arr));
    selectProfilesGlobal(arr.map(profile => profile.id));
  }

  if (error) {
    return (
      <Layout pageName="My Profiles" className="bg-gray-50">
        <ErrorAlert message={MESSAGES.error.generic} />
      </Layout>
    );
  }

  return (
    <Layout pageName="My Profiles" className="bg-gray-50">
      <ProfilesGroupsPseudoTabs activeTab="profiles" />
      <div className="space-y-8 pt-8">
        {actionResult &&
          (actionResult.outcome === 'success' ? (
            <SuccessAlert message={actionResult.message} />
          ) : (
            <ErrorAlert message={actionResult.message} />
          ))}
        <div className="flex flex-wrap items-end space-y-4 xl:space-y-0 justify-end gap-3 w-full">
          <Search
            id={`ProfileListGroupSearch-${page}-${pageSize}-${sort}-${order}-${search}-${selectedGroup.id}`}
            search={search}
            setSearch={setSearch}
            fetchQuery={searchQuery =>
              listProfilesInGroups(
                page,
                pageSize,
                sort,
                order,
                searchQuery,
                editorsGroup,
                selectedGroup.id,
              )
            }
            fetchEnabled={groups !== undefined && !isGroupsFetching}
          />
          <Filter
            id={`profile-list-group-filter-${page}-${pageSize}-${sort}-${order}-${search}-${selectedGroup.id}`}
            filter="groups"
            selected={selectedGroup}
            setSelected={setSelectedGroup}
            data={filterOptions}
          />
          <Sort
            id={`profile-list-group-sort-${page}-${pageSize}-${sort}-${order}-${search}-${selectedGroup.id}`}
            options={sortOptions}
            sort={sort}
            setSort={setSort}
            order={order}
            setOrder={setOrder}
            fetchQuery={(sortQuery, orderQuery) =>
              listProfilesInGroups(
                page,
                pageSize,
                sortQuery,
                orderQuery,
                search,
                editorsGroup,
                selectedGroup.id,
              )
            }
            fetchQueryEnabled={groups !== undefined && !isGroupsFetching}
          />
        </div>
        {profiles ? (
          profiles.data.length > 0 ? (
            <>
              <div className="hidden xl:block relative border border-solid rounded-lg overflow-hidden">
                <table
                  className={clsx('w-full max-w-full table-fixed', {
                    'opacity-40': isPaginationLoading,
                  })}
                >
                  <thead className="bg-gray-100 border-b border-gray-200">
                    <tr className="uppercase text-gray-900">
                      <th scope="col" className="py-3 px-4 w-[5%]">
                        <InputCheckbox
                          id="select-all-profiles-page"
                          value={selectAll}
                          // label has a margin -_-, which causes problems when there is no label
                          labelClassName=""
                          onChange={value => {
                            setSelectAll(value);

                            if (value) {
                              const arr = profiles.data.map(({ id }) => id);
                              setSelectedProfiles(new Set(profiles.data));
                              selectProfilesGlobal(arr);
                            } else {
                              setSelectedProfiles(new Set());
                              selectProfilesGlobal([]);
                            }
                          }}
                        />
                      </th>
                      <th
                        scope="col"
                        className="font-medium p-3 text-left text-sm w-1/4"
                      >
                        Name &amp; profile id
                      </th>
                      <th
                        scope="col"
                        className="font-medium p-3 text-left w-1/5 text-sm"
                      >
                        Contact information
                      </th>
                      <th
                        scope="col"
                        className="font-medium p-3 text-left w-[15%] text-sm"
                      >
                        Group
                      </th>
                      <th
                        scope="col"
                        className="font-medium p-3 text-sm w-[11%]"
                      >
                        App access
                      </th>
                      <th scope="col" className="font-medium p-3 text-sm">
                        Edit
                      </th>
                      <th scope="col" className="font-medium p-3 text-sm">
                        View
                      </th>
                      <th scope="col" className="font-medium py-3 px-4 text-sm">
                        Other
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {profiles.data.map((profile, index) => (
                      <ProfileListRow
                        key={index}
                        profile={profile}
                        setActionResult={setActionResult}
                        selected={selectedProfiles.has(profile)}
                        checkRow={() => handleCheckItem(profile)}
                        refreshProfiles={refreshProfiles}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="block xl:hidden space-y-4">
                <div className="bg-gray-100 flex xl:hidden flex-row border border-gray-300 rounded-md divide-x divide-gray-300">
                  <div className="pl-6 md:px-6 py-4">
                    <InputCheckbox
                      id="select-all"
                      value={selectAll}
                      onChange={value => {
                        setSelectAll(value);
                        if (value) {
                          const arr = profiles.data.map(({ id }) => id);
                          setSelectedProfiles(new Set(profiles.data));
                          selectProfilesGlobal(arr);
                        } else {
                          setSelectedProfiles(new Set());
                          selectProfilesGlobal([]);
                        }
                      }}
                    />
                  </div>
                  <div className="font-medium pl-4 md:px-6 py-4 uppercase">
                    Name, profile id, app access, contact information &amp;
                    group
                  </div>
                </div>
                {profiles.data.map((profile, index) => (
                  <ProfileListRowMobile
                    key={index}
                    profile={profile}
                    setActionResult={setActionResult}
                    selected={selectedProfiles.has(profile)}
                    checkRow={() => handleCheckItem(profile)}
                    refreshProfiles={refreshProfiles}
                  />
                ))}
              </div>
              {isPaginationLoading && (
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                  <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
                </div>
              )}
            </>
          ) : (
            <div className="py-32">
              <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                {MESSAGES.profile.list.empty.heading}
              </h3>
              <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                {MESSAGES.profile.list.empty.description}{' '}
                <a
                  href="https://tapt.io/pages/how-to-use"
                  rel="noreferrer"
                  target="_blank"
                >
                  Learn more.
                </a>
              </p>
            </div>
          )
        ) : (
          <div className="flex flex-col justify-center min-h-screen py-6 px-6 lg:px-8">
            <div>
              <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
            </div>
          </div>
        )}
        <Pagination
          id={`profile-list-group-page-${page}-${pageSize}-${sort}-${order}-${search}-${selectedGroup.id}`}
          className="bg-gray-50"
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setIsLoading={setIsPaginationLoading}
          fetchQuery={(pageQuery, pageSizeQuery) =>
            listProfilesInGroups(
              pageQuery,
              pageSizeQuery,
              sort,
              order,
              search,
              editorsGroup,
              selectedGroup.id,
            )
          }
          fetchQueryEnabled={groups !== undefined && !isGroupsFetching}
        />
      </div>
      <InfoPanelFooter
        className={selectedProfiles.size > 1 ? 'pl-4 pr-8' : '!-bottom-56'}
      >
        <ProfilesPageFooter
          selectedProfiles={Array.from(selectedProfiles)}
          setActionResult={setActionResult}
          refreshProfiles={refreshProfiles}
          onActionSuccess={() => {
            setSelectedProfiles(new Set());
            selectProfilesGlobal([]);
            if (selectAll) {
              setSelectAll(false);
            }
          }}
          hideEditSharedInfo
        />
      </InfoPanelFooter>
    </Layout>
  );
}

export default ProfileListGroupPage;
