import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

export function useResponsiveIntercomButton(predicate?: boolean) {
  const intercom = useIntercom();

  useEffect(() => {
    const verticalPadding = predicate !== false ? 80 : 0;
    intercom.update({ verticalPadding });

    return () => {
      if (predicate === undefined) {
        intercom.update({ verticalPadding: 0 });
      }
    };
  }, [predicate]);
}
